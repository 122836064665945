var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('form-payment', {
    attrs: {
      "payload": _vm.penjualan
    },
    on: {
      "submit": _vm.submit
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }